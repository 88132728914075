import { ProgressBarProps } from "./model";

export function ProgressBar({w=300, h=5,my=40,mx=0,stepName,currnetStep, stepsCount,hideSteps}:ProgressBarProps){

      return(
            <div class={`flex flex-col gap-20px items-center my-${my}px mx-${mx}px text-14px`}>
                  <p>{!hideSteps && `Steps ${currnetStep}/${stepsCount} :`}{` ${stepName}`} </p>
            <div class={`h-${h}px w-${w}px bg-gray-200 rounded-full  dark:bg-gray-700  `}>
            <div class="bg#highlight h-5px rounded-full dark:#highlight" style={`width: ${(currnetStep/stepsCount)*100}%`}></div>
          </div>
          </div>
      )
}